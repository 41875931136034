import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';

import { carouselImages } from '../../assets/home/carouselImages';
import { screenWidthSettings } from '../../constants';
import AnimatedButton from '../AnimatedButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselContainer: {
      color: 'white',
      // height: 'calc(100vh - 75px)', //using 100vh causes white space on bottom side
      height: 'calc(75vh - 75px)', //using 100vh causes white space on bottom side
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      overflowX: 'hidden',
      [theme.breakpoints.down(screenWidthSettings.tabletScreenSize)]: {
        height: 'auto',
      },
    },
    carousel: {
      color: 'white',
      // height: 'auto', //setting height of carousel does not change height of slideContainer
      height: 'auto', //setting height of carousel does not change height of slideContainer
      width: '100%',
      maxWidth: screenWidthSettings.maxScreenWidth,
      overflowX: 'hidden',
      display: 'flex',
      alignItems: 'flex-end',
    },
    slideContainer: {
      position: 'relative', // has to be relative if we want textContainer to be absolutely positioned
      width: '100%',
      height: '100%', // ensures images fill up height of slideContainer
      maxHeight: 'calc(75vh - 75px)',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'scale-down', // scale image to fit container without stretching, cropping if necessary
    },
    textContainer: {
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      width: '100%',
      height: 'auto',
      padding: '2rem 5rem 3rem 5rem',
      color: 'inherit',
      background:
        'linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0.6) 60%, rgba(0,0,0,0))',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.down(768)]: {
        height: '100%',
        background: 'rgba(0,0,0,0.5)',
        alignItems: 'center',
        justifyContent: 'space-around',
      },
    },
    carouselButtonContainer: {
      marginTop: '10px',
    },
  })
);

const NewsCarousel = () => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.mobileScreenSize.toString()}px)`
  );
  const isTabletScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.tabletScreenSize.toString()}px)`
  );
  const isLargeScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.largeLaptopScreenSize.toString()}px)`
  );

  return (
    <section className={classes.carouselContainer}>
      <Carousel
        className={classes.carousel}
        showStatus={false}
        showThumbs={false}
        dynamicHeight={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        autoPlay={true}
        stopOnHover={true}
        swipeable={true}
        interval={5000}
        transitionTime={200}
      >
        {carouselImages.map((carouselItem) => {
          return (
            <div className={classes.slideContainer} key={carouselItem.title}>
              <img
                className={classes.image}
                src={carouselItem.image}
                alt={carouselItem.description}
              />
              <div className={classes.textContainer}>
                <Typography
                  align={isTabletScreen ? 'center' : 'left'}
                  color='inherit'
                  variant={
                    isMobileScreen
                      ? 'h5'
                      : isTabletScreen
                      ? 'h4'
                      : isLargeScreen
                      ? 'h3'
                      : 'h2'
                  }
                  component='h1'
                  style={{ fontWeight: 700 }}
                >
                  {carouselItem.title}
                </Typography>
                {carouselItem.buttonText && carouselItem.link && (
                  <div className={classes.carouselButtonContainer}>
                    <AnimatedButton
                      buttonText='Learn More'
                      buttonColor='white'
                      buttonHoverColor='rgba(0,0,0,0.1)'
                      fontSize='1.5rem'
                      link={carouselItem.link}
                      target='_blank'
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default NewsCarousel;
