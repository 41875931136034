/* Images below will be shown on the home page carousel */

// import type of carouselImages as CarouselImages
// a type error will be raised if the shape of carouselImages does not match CarouselImages
import { CarouselImages } from '../../types';


import babyCheetah from './carousel_1.jpg';
import humanoid from './humanoid.jpg';
import teleop_arm from './teleop_arm.jpg';
import humanoid_gif from './humanoid.gif';

export const carouselImages: CarouselImages = [
  {
    image: humanoid_gif,
    title: 'The MIT Humanoid',
    description: 'mit humanoid',
    buttonText: 'Learn More',
    link:
      'https://spectrum.ieee.org/mit-dynamic-acrobatic-humanoid-robot',
  },
  {
    image: humanoid,
    title: 'The MIT Humanoid',
    description: 'mit humanoid',
    buttonText: 'Learn More',
    link:
      'https://spectrum.ieee.org/mit-dynamic-acrobatic-humanoid-robot',
  },
  {
    image: teleop_arm,
    title: 'Proprioceptive Manipulation',
    description: 'mit teleop arm',
    buttonText: '',
    link: '',
  },
  {
    image: babyCheetah,
    title: 'The MIT Mini Cheetah',
    description: 'mini cheetah',
    buttonText: 'Learn More',
    link:
      'https://news.mit.edu/2019/mit-mini-cheetah-first-four-legged-robot-to-backflip-0304',
  },
];
